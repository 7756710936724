<template>
  <div>
    <v-btn color="orange" class="" icon @click="dialog = true"
      ><v-icon>mdi-pencil</v-icon></v-btn
    >
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title>Edit Supplier</v-card-title>
        <v-card-text>
          <v-text-field
            label="Supplier Name"
            v-model="item.supplier_name"
          ></v-text-field>
          <v-text-field
            label="Contact Person"
            v-model="item.contact_person"
          ></v-text-field>
          <v-text-field
            label="Supplier Contact"
            v-model="item.supplier_contact_no"
          ></v-text-field>
          <v-text-field
            label="Contact Platform"
            v-model="item.contact_platform"
          ></v-text-field>
          <v-text-field
            label="Supplier Address"
            v-model="item.supplier_address"
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-percent"
            label="Vat Percentage"
            v-model="item.vat_percentage"
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-cart"
            label="Item Sales"
            v-model="item.item_sales"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="submit" :loading="loading">Edit</v-btn>
          <v-btn @click="dialog = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["item"],
  data() {
    return {
      loading: false,
      dialog: false,
    };
  },
  methods: {
    ...mapActions({
      edit_supplier: "supplier/edit_supplier",
    }),
    async submit() {
      this.loading = true;
      await this.edit_supplier(this.item);
      alert("You have successfully edited a new supplier!");
      this.loading = false;
      //   console.log(this.item);
    },
  },
};
</script>

<style lang="scss" scoped></style>
