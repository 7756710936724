<template>
  <v-container class="mt-16">
    <div class="">
      <div v-if="ready">
        <v-card flat>
          <v-card-title class="d-flex">
            <h2>Suppliers</h2>
            <v-spacer></v-spacer>
            <add-supplier-modal />
            <v-btn
              color=""
              class="mt-3"
              icon
              x-large
              @click="refresh"
              :loading="loading"
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
          </v-card-title>
          <v-card-text>
            <v-text-field
              label="Search"
              v-model="search"
              prepend-icon="mdi-magnify"
            ></v-text-field>
            <v-data-table
              :search="search"
              :items="suppliers"
              :headers="headers"
            >
              <template v-slot:item.action="{ item }">
                <div class="d-flex">
                  <edit-supplier-modal :item="item" />
                  <v-btn icon color="red" @click="del(item)"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
      <div v-else><h2 class="text-center">Data table is loading...</h2></div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AddSupplierModal from "../components/Supplier/addSupplierModal.vue";
import EditSupplierModal from "../components/Supplier/editSupplierModal.vue";

export default {
  components: {
    AddSupplierModal,
    EditSupplierModal,
  },
  data() {
    return {
      search: "",
      loading: false,
      ready: false,
      headers: [
        {
          text: "Action",
          value: "action",
        },
        {
          text: "ID",
          value: "id",
        },
        { text: "Supplier Name", value: "supplier_name" },
        {
          text: "Contact Person",
          value: "contact_person",
        },
        {
          text: "Contact Number",
          value: "supplier_contact_no",
        },
        {
          text: "Contact Platform",
          value: "contact_platform",
        },

        {
          text: "Supplier Address",
          value: "supplier_address",
        },
        {
          text: "Total Amount Spent",
          value: "total_amount_spent",
        },
        {
          text: "Vat Percentage",
          value: "vat_percentage",
        },
        { text: "Item Sales", value: "item_sales" },
        {
          text: "Created at",
          value: "created_at",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      suppliers: "supplier/suppliers",
    }),
  },
  methods: {
    del(item) {
      let x = window.confirm("Are you sure you want to delete this row?");
      if (x) {
        this.loading = true;
        this.delete_supplier(item.id).then(() => {
          alert("Successfully deleted");
        });
        this.loading = false;
      }
    },
    refresh() {
      this.loading = true;
      this.get_suppliers().then(() => {
        this.loading = false;
      });
    },
    ...mapActions({
      get_suppliers: "supplier/get_suppliers",
      delete_supplier: "supplier/delete_supplier",
    }),
  },
  async created() {
    // check if admin or not
    if (this.suppliers.length == 0)
      await this.get_suppliers().then(() => {
        this.ready = true;
      });
    this.ready = true;
  },
};
</script>

<style></style>
